.label0 {
  left: 9mm;
  top: 4mm;
}

.label1 {
  left: 9mm;
  top: 106.1mm;
}

.label2 {
  left: 148.5mm;
  top: 4mm;
}

.label3 {
  left: 148.5mm;
  top: 106.1mm;
}

@page {
  size: A4 landscape;

  @top-left {
    margin: 0 0 0 0;
    width: 0;
  }

  @top-left-corner {
    margin: 0 0 0 0;
    width: 0;
  }

  @top-center {
    margin: 0 0 0 0;
    width: 0;
  }

  @top-right {
    margin: 0 0 0 0;
    width: 0;
  }

  @top-right-corner {
    margin: 0 0 0 0;
    width: 0;
  }

  @left-top {
    margin: 0 0 0 0;
    width: 0;
  }

  @left-middle {
    margin: 0 0 0 0;
    width: 0;
  }

  @left-bottom {
    margin: 0 0 0 0;
    width: 0;
  }

  @right-top {
    margin: 0 0 0 0;
    width: 0;
  }

  @right-middle {
    margin: 0 0 0 0;
    width: 0;
  }

  @right-bottom {
    margin: 0 0 0 0;
    width: 0;
  }

  @bottom-left {
    margin: 0 0 0 0;
    width: 0;
  }

  @bottom-left-corner {
    margin: 0 0 0 0;
    width: 0;
  }

  @bottom-center {
    margin: 0 0 0 0;
    width: 0;
  }

  @bottom-right {
    margin: 0 0 0 0;
    width: 0;
  }

  @bottom-right-corner {
    margin: 0 0 0 0;
    width: 0;
  }
}

@page :left {
  margin-left: 0;
}

@page :right {
  margin-right: 0;
}
