@font-face {
  font-family: 'Bebrush';
  src: local('Bebrush'), url(../Bebrush.otf);
}

.carousel-slider {
  cursor: grab;
  position: relative;
  width: 100%;
}

.carousel {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  cursor: grab;
  position: relative;
  width: 100%;
}

.content {
  justify-content: center;
  display: flex;
  flex: 1;
}

.content-container {
  background-color: #FFE9EE;
  display: flex;
  flex: 1;
  justify-content: center;
}

.body {
  align-items: flex-start;
  color: white;
  display: flex;
  flex-direction: column;
  flex: 1;
  font-size: calc(10px + 2vmin);
  min-height: 100vh;
}

.image {
  max-height: 100vh;
  object-fit: cover;
}

@media screen and (max-aspect-ratio: 1/1) {
  .body {
    align-items: center;
    /* mobile viewport bugfix */
    min-height: -webkit-fill-available;
    /* mobile viewport bugfix */
    max-height: -webkit-fill-available;
  }
}

@media screen and (min-aspect-ratio: 1/1) {
  .body {
    flex-direction: row;
  }
}
