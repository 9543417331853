.canceled-or-success-container {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  margin-left: 8vmin;
  margin-right: 8vmin;
}

.canceled-or-success-title {
  color: #FFC0CF;
  font-family: 'Montserrat', sans-serif;
  font-size: x-large;
  font-weight: 600;
  margin-bottom: 0.5vmin;
  text-align: center;
  white-space: pre-line;
}

.canceled-or-success-subtitle {
  color: #FFC0CF;
  font-family: 'Montserrat', sans-serif;
  font-size: large;
  font-weight: 600;
  margin-top: 0.5vmin;
  text-align: center;
}

.canceled-or-success-star {
  height: 2em;
  margin-bottom: 1em;
  width: 2em;
}

.canceled-or-success-button {
  background: #FFC0CF;
  border: none;
  border-radius: 20px;
  color: white;
  font-family: 'Montserrat', sans-serif;
  font-size: 1em;
  font-weight: 600;
  letter-spacing: 0.1em;
  margin-top: 1vmin;
  outline: none;
  padding: 1em;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
}

@media screen and (max-aspect-ratio: 1/1) {
  .canceled-or-success-container {
    margin-bottom: 50vmin;
  }

  .canceled-or-success-title {
    font-size: 1.4em;
  }

  .canceled-or-success-subtitle {
    font-size: 1.2em;
  }
}
